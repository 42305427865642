(function() {
    'use strict';

    angular.module('EntrakV5').constant('LANG', {
"en": {
"lang": {
"en": "English",
"zh": "繁體中文",
"cn": "简体中文",
"enShort": "Eng",
"zhShort": "繁",
"cnShort": "简",
"logout": "Sign out"
},
"button": {
"invite": "Invite",
"activate": "Activate",
"confirm": "Confirm",
"save": "Save",
"cancel": "Cancel",
"back": "Back",
"add": "Add",
"edit": "Edit",
"delete": "Delete",
"signIn": "Sign in",
"next": "Next",
"reset": "Reset",
"goSignIn": "Go back to sign in",
"create": "Create"
},
"error": {
"invalidPwd": "Sorry, your password is incorrect, please try again.",
"invalidLogin": "Sorry, your email is incorrect, please try again.",
"generalLoginFail": "Unable to sign in.",
"checkPwd": "Please check your password again.",
"generalResetPwdFail": "Unable to reset password.",
"generalActivateFail": "Unable to activate account.",
"invalidEmail": "Invalid email format.",
"generalRegFail": "Unable to register account.",
"tooManyTry": "Too many failed login attempts, please try again after 5 minutes.",
"selfRegDisabled": "Your administrator has not enabled self-registration for your system. Please contact your administrator for help in creating an account.",
"captchaFail": "Verification code does not match.",
"contactAdmin": "Oops...something's wrong here...please contact your system administrator.",
"pwdFormatInvalid": "Invalid password format, please check your password again.",
"invalidLink": "Your link is invalid or expired, please get the link again.",
"activationLinkInvalid": "This activation link is invalid or expired, please contact your administrator to get a new one.",
"activatedAlready": "This account is already activated.",
"emptyName": "Please input your name."
},
"login": {
"signIn": "Sign in",
"email": "Email",
"userName": "Email",
"password": "Password",
"confirmPwd": "Confirm password",
"forgotPwd": "Forgot password",
"loginWithMS": "Sign in with Microsoft",
"signInLandlord": "Sign in as landlord",
"resetPwd": "Reset password",
"resetDoneDesc": "Your password has been reset. Please sign in again.",
"resetEmailSentDesc": "The reset link is sent to your email. Please check and reset password.",
"AccountActivatedDesc": "Your account is activated now, you can go to the sign in page to sign in with your account.",
"dontHaveAccount": "Don't have an account?",
"createAccount": "Create account",
"setUpAccount": "Set up account",
"setUpPwd": "Set up password",
"fName": "First name",
"lName": "Last name",
"invalidTokenDesc": "Your token is invalid or expired, you will be redirected to the login page in ${second} seconds...",
"profileCreatedDesc": "An account activation link is sent to your email. Please check your email and activate your account, you will be redirected to the login page in ${second} seconds...",
"validationCode": "Validation code"
},
"lbl": {
"pwdContain": "Password must contain:",
"pwdLowerCase": "at least 1 lower case letter",
"pwdUpperCase": "at least 1 upper case letter",
"pwdSpecialChar": "at least 1 special character",
"pwdNumber": "at least 1 number",
"pwdMin": "at least ${min} characters",
"pwdMinMax": "${min} to ${max} characters"
},
},
"zh": {
"lang": {
"en": "English",
"zh": "繁體中文",
"cn": "简体中文",
"enShort": "Eng",
"zhShort": "繁",
"cnShort": "简",
"logout": "登出"
},
"button": {
"invite": "邀請",
"activate": "激活",
"confirm": "確認",
"save": "保存",
"cancel": "取消",
"back": "返回",
"add": "新增",
"edit": "編輯",
"delete": "刪除",
"signIn": "登入",
"next": "下一步",
"reset": "重設",
"goSignIn": "回到登入頁面",
"create": "創建"
},
"error": {
"invalidPwd": "對不起，你的密碼不正確，請重新輸入。",
"invalidLogin": "對不起，你的電郵地址不正確，請重新輸入。",
"generalLoginFail": "無法登入。",
"checkPwd": "請再確認一下你的密碼。",
"generalResetPwdFail": "無法重設密碼。",
"generalActivateFail": "無法激活帳戶。",
"invalidEmail": "電郵地址不正確。",
"generalRegFail": "無法登記帳戶。",
"tooManyTry": "登入失敗次數過多，請於5分鐘得再嘗試。",
"selfRegDisabled": "你的管理員尚未啟用自我註冊功能。請聯絡你的管理員以協助創建帳戶。",
"captchaFail": "驗証碼不正確。",
"contactAdmin": "糟糕了⋯⋯好像出了什麼問題，請聯絡你的系統管理員。",
"pwdFormatInvalid": "密碼格式符合要求，請再次檢查您的密碼。",
"invalidLink": "你的連結已經失效或過期，請取得新的連結。",
"activationLinkInvalid": "此啟動賬戶的連結不正確或已過期，請聯絡你的管理員取得新的連結。",
"activatedAlready": "此賬戶已經啟動。",
"emptyName": "請輸入你的姓名。"
},
"login": {
"signIn": "登入",
"email": "電郵地址",
"userName": "電郵地址",
"password": "密碼",
"confirmPwd": "確認密碼",
"forgotPwd": "忘記密碼",
"loginWithMS": "使用Microsoft帳戶登入",
"signInLandlord": "登入業主板面",
"resetPwd": "重設密碼",
"resetDoneDesc": "已經重設你的密碼，請重新登入。",
"resetEmailSentDesc": "重設密碼的連結已經發送到你的電郵，請到郵箱開啟並重設你的密碼。",
"AccountActivatedDesc": "你的賬戶已經啟動，你可以經由以下按鍵回到登入版面登入你的賬戶。",
"dontHaveAccount": "還沒有帳戶？",
"createAccount": "創建帳戶",
"setUpAccount": "帳戶設定",
"setUpPwd": "密碼設定",
"fName": "名字",
"lName": "姓氏",
"invalidTokenDesc": "你的連結已經失效或過期，${second}秒後將會跳回登入板面。",
"profileCreatedDesc": "激活帳戶的連結已經發送到你的電郵，請到郵箱開啟並激活你的帳戶。${second}秒後將會跳回登入板面。",
"validationCode": "驗證碼"
},
"lbl": {
"pwdContain": "密碼必須包含：",
"pwdLowerCase": "至少一個小寫字母",
"pwdUpperCase": "至少一個大寫字母",
"pwdSpecialChar": "至少一個特殊字符",
"pwdNumber": "至少一個數字",
"pwdMin": "至少${min}個字符",
"pwdMinMax": "${min}至${max}個字符"
},
},
"cn": {
"lang": {
"en": "English",
"zh": "繁體中文",
"cn": "简体中文",
"enShort": "Eng",
"zhShort": "繁",
"cnShort": "简",
"logout": "登出"
},
"button": {
"invite": "邀请",
"activate": "激活",
"confirm": "确认",
"save": "保存",
"cancel": "取消",
"back": "返回",
"add": "新增",
"edit": "编辑",
"delete": "删除",
"signIn": "登录",
"next": "下一步",
"reset": "重设",
"goSignIn": "回到登入页面",
"create": "创建"
},
"error": {
"invalidPwd": "对不起，你的密码不正确，请重新输入。",
"invalidLogin": "对不起，你的电邮地址不正确，请重新输入。",
"generalLoginFail": "无法登入。",
"checkPwd": "请再确认一下你的密码。",
"generalResetPwdFail": "无法重设密码。",
"generalActivateFail": "无法激活帐户。",
"invalidEmail": "电邮地址不正确。",
"generalRegFail": "无法登记帐户。",
"tooManyTry": "登入失败次数过多，请于5分钟得再尝试。",
"selfRegDisabled": "你的管理员尚未启用自我注册功能。请联络你的管理员以协助创建帐户。",
"captchaFail": "验证码不正确。",
"contactAdmin": "糟糕了⋯⋯好像出了什么问题，请联络你的系统管理员。",
"pwdFormatInvalid": "err.pwdFormatInvalid",
"invalidLink": "你的连结已经失效或过期，请取得新的连结。",
"activationLinkInvalid": "",
"activatedAlready": "",
"emptyName": ""
},
"login": {
"signIn": "登录",
"email": "电邮地址",
"userName": "电邮地址",
"password": "密码",
"confirmPwd": "确认密码",
"forgotPwd": "忘记密码",
"loginWithMS": "使用Microsoft帐户登入",
"signInLandlord": "登入业主板面",
"resetPwd": "重设密码",
"resetDoneDesc": "已经重设你的密码，请重新登入。",
"resetEmailSentDesc": "重设密码的连结已经发送到你的电邮，请到邮箱开启并重设你的密码。",
"AccountActivatedDesc": "你的账户已经启动，你可以经由以下按键回到登入版面登入你的账户。",
"dontHaveAccount": "还没有帐户？",
"createAccount": "创建帐户",
"setUpAccount": "帐户设定",
"setUpPwd": "密码设定",
"fName": "名字",
"lName": "姓氏",
"invalidTokenDesc": "你的连结已经失效或过期，${second}秒后将会跳回登入板面。",
"profileCreatedDesc": "激活帐户的连结已经发送到你的电邮，请到邮箱开启并激活你的帐户。 ${second}秒后将会跳回登入板面。",
"validationCode": "验证码"
},
"lbl": {
"pwdContain": "密码必须包含：",
"pwdLowerCase": "至少一个小写字母",
"pwdUpperCase": "至少一个大写字母",
"pwdSpecialChar": "至少一个特殊字符",
"pwdNumber": "至少一个数字",
"pwdMin": "至少${min}个字符",
"pwdMinMax": "${min}至${max}个字符"
},
},

  });

})();